export default {
    "props": {
        "cardProps": {
            // "variant": "outlined",
            "elevation": 0,
        },
        "cardActionProps": {
            "color": "primary",
        },
        "cardActionButtonProps": {
            "color": "inherit",
            // "size": "large",
        },
        "cardContentProps": {
            "container": true,
            "spacing": 3,
            "cardContentItem": {
                "item": true,
                "cardContentItemContainer": {
                    "container": true,
                    "direction": "column",
                },
                "cardContentItemItem": {
                    "item": true,
                },
            },
            "cardContentItemName": {
                "component": "span",
                "variant": "subtitle1",
            },
            "cardContentItemParams": {
                "component": "span",
                "variant": "subtitle2",
            },
        },
        "showBoxContainer": true,
        "_data": [
            {
                "id": "action1",
                "title": "1+ Bedroom houses for sale in London between £90,0000 and £600,0000",
                "content": [
                    {
                        "id": "asdad",
                        "title": "Location:",
                        "param": "Camberwell",
                    },
                    {
                        "id": "cvdssdf",
                        "title": "Type of property:",
                        "param": "House",
                    },
                    {
                        "id": "asvxdad",
                        "title": "Min Price:",
                        "param": "£90000",
                    },
                    {
                        "id": "sdgdvvc",
                        "title": "Max Price:",
                        "param": "£140000",
                    },
                    {
                        "id": "cvbce",
                        "title": "Bedrooms:",
                        "param": "1 to 4",
                    },
                ],
            },
            {
                "id": "action2",
                "title": "Offer of £465,000 received for Margaret Street, SN4 9QS ",
                "date": "24/11/2019",
                "time": "14:34pm"
            },
            {
                "id": "action3",
                "disabled": "true",
                "title": "Offer of £465,000 received for Margaret Street, SN4 9QS ",
                "date": "24/11/2019",
                "time": "14:34pm"
            },
            {
                "id": "action4",
                "disabled": "true",
                "title": "Offer of £465,000 received for Margaret Street, SN4 9QS ",
                "date": "24/11/2019",
                "time": "14:34pm"
            }
        ]
    },
    "sx": (theme) => ({
        ...(theme.props.showBoxContainer && {
            "& .content": {
                "display": "flex",
                "flexFlow": "column",
            },
        }),
        "& .content": {
            "display": "flex",
            "flexFlow": "column",
        },
        "& .box-container": {
            "display": "flex",
            "flexWrap": "wrap",
            "alignItems": "center",
            "border": "1px solid #DDDDDD",
            "borderRadius": "4px",
            "padding": theme.spacing(5),
            "order": "1",
            "& .box-image": {
                "background": theme.palette.grey.grey3,
                "& svg": {
                    "fontSize": "2rem"
                }
            },
        },
        "& .box-image-container": {
            "display": "none",
            [theme.breakpoints.up('lg')]: {
                "display": "block"
            },
        },
        "& .box-image-container, .box-btn-container, .box-content": {
            [theme.breakpoints.down('lg')]: {
                "flexBasis": "100%",
                "maxWidth": "100%",
            },
        },
        "& .box-content": {
            [theme.breakpoints.down('lg')]: {
                "padding-bottom": "10px",
                "flexBasis": "100%",
                "maxWidth": "100%",
            },
            [theme.breakpoints.up('lg')]: {
                "paddingLeft": "10px",
            },
        },
        "& .box-btn-container": {
            "textAlign": "left",
            "paddingTop": theme.spacing(1),
            [theme.breakpoints.up('lg')]: {
                "textAlign": "right",
                "paddingTop": theme.spacing(0),
            }
        },
        "& .box-container-title": {
            "fontFamily": theme.primaryFontFamily,
            "fontWeight": theme.typography.fontWeightBold,
            "fontSize": theme.bodyMediumSize,
            "lineHeight": theme.h5LineH,
            "marginBottom": "5px",
            [theme.breakpoints.up('xl')]: {
                "marginBottom": "10px",
            },
        },
        "& .box-container-text": {
            "fontFamily": theme.secondaryFontFamily,
            "fontSize": theme.bodyMedium,
            "lineHeight": theme.lineHeightBMedium,
            "fontWeight": "normal",
            [theme.breakpoints.down('md')]: {
                "marginBottom": "5px",
                "display": "block"
            },
            "color": theme.palette.grey.grey2
        },
        "& .savedSearchResults-card": {
            "marginBottom": 0,
            "paddingTop": theme.spacing(1),
            "paddingBottom": theme.spacing(1),
            "&:last-child": {
                "marginBottom": theme.spacing(4),
            },
            "&.disabled": {
                "& .savedSearchResults-cardHeader": {
                    "& > div": {
                        "opacity": 0.5
                    },
                    "& .savedSearchResults-action": {
                        "opacity": 1
                    }
                },
            }
        },
        "& .savedSearchResults-cardActionButton": {
            "backgroundColor": theme.palette.grey.grey4,
            "color": theme.palette.primary.dark,
            [theme.breakpoints.up('md')]: {
                "width": "80px",
                "height": "80px",
            },
        },
        "& .savedSearchResults-cardHeader": {
            "padding": theme.spacing(1,0),
            [theme.breakpoints.up('sm')]: {
                "padding": theme.spacing(1.5,0),
            },
            "& .savedSearchResults-avatar": {
                "display": "none",
                "marginRight": theme.spacing(1.875),
                [theme.breakpoints.up('sm')]: {
                    "marginRight": theme.spacing(3.5),
                }
            },
            "& .savedSearchResults-title": {
                "fontSize": theme.bodySmall,
                "marginBottom": theme.spacing(0.25),
                [theme.breakpoints.up('sm')]: {
                    "fontSize": theme.bodyLarge,
                    "lineHeight": theme.lineHeightBLarge,
                    "marginBottom": theme.spacing(0.5),
                }
            },
            "& .savedSearchResults-subheader": {
                "color": "#6A6A6A",
                [theme.breakpoints.up('sm')]: {
                    "fontSize": theme.bodyMedium,
                    "lineHeight": theme.lineHeightBMedium,
                }
            },
            "& .savedSearchResults-action": {
                "alignSelf": "center",
                "marginRight": theme.spacing(0.5),
            }
        },
        "& .savedSearchResults-cardContent": {
            "display": "none",
            [theme.breakpoints.up("lg")]: {
                "display": "flex",
            },
            "& .cardContentItemName": {
                "fontWeight": theme.typography.fontWeightBold,
                "color": theme.palette.text.primary,
            },
            "& .cardContentItemParams": {
                "color": theme.palette.text.primary,
                "textTransform": "capitalize",
            }
        },
    }),
}
