import * as React from 'react';
import { isEmpty } from 'lodash';
import { querystring } from '@myaccountServices/store/utils';
import { withSubtheme } from '@starberryComponentsMui';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ComponentLayout from '@myaccount/ComponentLayout';
import ActionModalDefault from '@myaccount/modals/default';
import DialogChildren from './dialogChildren';
import DialogChildrenCustom from './dialogChildrenCustom';
import DialogChildrenStepOne from './dialogChildrenStepOne';
import DialogChildrenStepTwo from './dialogChildrenStepTwo';
import { FormStoreProvider, useFormState, usePropertyActionState } from "@myaccountServices";
import CircularProgress from '@myaccount/progress/CircularProgress'
import { getCall } from "@myaccountServices/store/api"

import defaults from './defaults';

const SimpleModuleWrap = withSubtheme((props) => {
    const {
        className,
        title,
        moreLabel,
        moreLinkUrl,
        titleLinkMoreTooltipProps,
        imgUrl,
        imgTitle,
        moduleTitle,
        content,
        btnLabel,
        onlyModal,
        openDefault,
        setShow,

        cardModuleProps,
        cardModuleTitleProps,
        cardModuleContentProps,
        cardModuleButtonProps,
        initialStep,
        // initialFetch,
        // initialFetchProps,
        customApiCall,
        actionCardDialogChildrenStyles,
    } = props;

    const { state, services } = useFormState()
    const { services: actionServices } = usePropertyActionState()
    const step = state?.step || state?.formState?.step || initialStep || '';
    //
    // console.log("state => ", state);
    // console.log("step => ", step);

    const [open, setOpen] = React.useState(openDefault || false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = (value) => {
        value.preventDefault();
        services.resetForm();
        if (state?.data?.postAction === 'add') {
            actionServices.updatePropertyStatus({...state.data, key:'worksOrders.open_items'});
        }
        setOpen(false);
        if (setShow) {
            setShow(false);
        }
    };

    React.useEffect(() => {
        // Calling the api
        if (step === 'stepTwo' && open) {
            let params = {property_type: `${state?.formState?.property_type}`, postcode: `${state?.formState?.postcode || state?.formState?.postalCode || state?.formState?.property_address}`}
            if (!isEmpty(customApiCall)) {
                services.changeFormState({loading: true})
                let paramsAlt = {type: params.property_type, postcode: params.postcode.replace(/ /g, '')}
                getCall(`${customApiCall.url}?${querystring(paramsAlt)}`).then((res) => {
                    if (res.status === 200) {
                        if (isEmpty(res.data.slots)) {
                            services.changeFormState({
                                loading: false,
                                slots: [],
                                error:false,
                                message: ''/*err.response.status*/,
                                step: 'actionCustomSlot',
                                introMessage: ``
                            })
                        } else {
                            services.changeFormState({loading: false, preLoaded: true, slots: res.data,  message:''})
                        }

                    } else {
                        services.changeFormState({loading: false, slots: [], error:true, message: res.status})
                    }
                }).catch(function(err) {
                    console.log("Error:", err.response);
                    // To display error message
                    // services.changeFormState({loading: false, slots: [], error:true, message: 'Please enter a valid postcode'/*err.response.status*/, step: 'stepOne'})
                    services.changeFormState({
                        loading: false,
                        slots: [],
                        error:false,
                        message: ''/*err.response.status*/,
                        step: 'actionCustomSlot',
                        introMessage: `Sorry, there were no appointments found for this postcode. To arrange your valuation, please submit your request and we will do our best to accomodate you`
                    })
                })
            } else {
                services.getAvailabilitySlotsByPostcode(params)
            }
        }
        // if (initialFetch)
        //     services.getAvailabilitySlotsByPostcode(initialFetchProps || {})
    }, [step, open]) // eslint-disable-line react-hooks/exhaustive-deps

    let dialogContent = '';
    if (state.loading) {
        dialogContent = <CircularProgress />
    } else {
        if (step === 'stepOne') {
            dialogContent = <DialogChildrenStepOne
                handleClose={handleClose}
            />
        } else if (step === 'stepTwo') {
            dialogContent = <DialogChildrenStepTwo
                handleClose={handleClose}
            />
        } else if (step === 'actionCustomSlot') {
            dialogContent = <DialogChildrenCustom
                handleClose={handleClose}
                actionCardDialogChildrenStyles={actionCardDialogChildrenStyles}
            />
        } else {
            dialogContent = <DialogChildren handleClose={handleClose} />
        }
    }

    if (openDefault && onlyModal) {
        return(
            <ActionModalDefault
                open={open}
                handleClickOpen={handleClickOpen}
                handleClose={handleClose}
                children={dialogContent}
                loading={state.loading}
                title={moduleTitle ? moduleTitle : "Report a New Fault"}
            />
        )
    }

    return(
        <ComponentLayout
            className={className}
            title={title}
            moreLabel={moreLabel}
            moreLinkUrl={moreLinkUrl}
            titleLinkMoreTooltipProps={titleLinkMoreTooltipProps}
        >
            <Card classes={{root: "simpleModule-cardModule"}} {...cardModuleProps}>
                {imgUrl &&
                    <CardMedia
                        classes={{media: "simpleModule-cardModule-media"}}
                        image={imgUrl}
                        title={imgTitle}
                    />
                }
                <CardContent classes={{root: "simpleModule-cardContent"}}>
                    {moduleTitle &&
                        <Typography classes={{root: "simpleModule-cardModuleTitle"}} {...cardModuleTitleProps}>
                            {moduleTitle}
                        </Typography>
                    }
                    {content &&
                        <Typography classes={{root: "simpleModule-cardModuleContent"}} {...cardModuleContentProps}>
                            {content}
                        </Typography>
                    }
                </CardContent>
                {btnLabel &&
                    <>
                        <CardActions classes={{root: "simpleModule-cardActions"}}>
                            <Button onClick={handleClickOpen} classes={{root: "simpleModule-cardModuleButton"}} {...cardModuleButtonProps}>{btnLabel}</Button>
                        </CardActions>
                        <ActionModalDefault
                            open={open}
                            handleClickOpen={handleClickOpen}
                            handleClose={handleClose}
                            children={dialogContent}
                            loading={state.loading}
                            title={moduleTitle ? moduleTitle : "Report a New Fault"}
                        />
                    </>
                }
            </Card>
        </ComponentLayout>
    )
}, 'simpleModule', defaults)

const SimpleModule = (props) => {
    return (
        <FormStoreProvider>
            <SimpleModuleWrap {...props} />
        </FormStoreProvider>
    )
}

export default SimpleModule
